import React, { useEffect } from "react";
import SEO from "../components/SEO";
import api from "../services/api";
import Login from "./login";

const IndexPage = props => {
    const [auth, setAuth] = React.useState();

    useEffect(() => {
        let token = window.localStorage.getItem("session-token");
        if (token && token.length > 0) {
            setAuth(true);
            api.get("profile/show", {
                headers: {
                    Authorization: "Bearer " + token,
                },
            })
                .then(response => {
                    window.location.href = "/dashboard";
                })
                .catch(error => {
                    setAuth(false);
                    window.localStorage.clear();
                });
        } else {
            setAuth(false);
            window.localStorage.clear();
        }
    }, []);

    return (
        <>
            <SEO title="Login" />
            <Login email={props.location?.state?.email} auth={auth} />
        </>
    );
};

export default IndexPage;
